import React from 'react';
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';
import BottomAction from '../sections/home3/BottomAction';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        className="w-100 mb-6"
        alt={node.data.target.title}
        src={node.data.target.file.url}
      />
    ),
  },
};

const Post = ({ data }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <div className="mb-7 d-inline-flex align-items-center flex-wrap">
                    <span className="d-block d-inline-flex align-items-center mr-sm-6 text-bali-gray">
                      <i className="fa fa-clock mr-1 text-bali-gray position-relative"></i>
                      {data.contentfulBlogPost.published}
                    </span>
                    <span className="d-block d-inline-flex align-items-center mr-sm-6 text-bali-gray">
                      <i className="fa fa-user mr-1 text-bali-gray position-relative"></i>
                      {data.contentfulBlogPost.author}
                    </span>
                    <p className="bg-dark-green-op1 font-size-3 font-weight-medium badge mb-0 py-2 px-4 ml-8">
                      <span className="text-dark-green">Blog Post</span>
                    </p>
                  </div>
                  <h2 className="font-size-11 mb-8">
                    {data.contentfulBlogPost.title}
                  </h2>
                  <p className="font-size-7 mb-7">
                    {data.contentfulBlogPost.description.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8 font-size-5 heading-default-color">
                    {renderRichText(data.contentfulBlogPost.body, options)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomAction />
      </PageWrapper>
    </>
  );
};

export const pageQuery = graphql`
  query post($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      published(formatString: "MM/DD/YY")
      title
      description {
        description
      }
      author
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Post;
